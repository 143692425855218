import settings from "@/settings";

let get = () => {
  return fetch(`${settings.apetrusApi}/politicasprivacidade/posto/`, {
    method: "GET",
    mode: "cors",
    cache: "default"
  });
};

export default {
  get
};
