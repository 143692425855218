<template>
  <v-app :style="{ backgroundColor: 'white' }">
    <router-view></router-view>
    <v-snackbar
      :timeout="6000"
      vertical
      :color="snackbar.color"
      v-model="snackbar.active"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.active = false">
        FECHAR
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    snackbar: {
      text: "",
      color: "",
      active: false
    }
  }),
  watch: {
    info(val) {
      if (val) {
        this.snackbar.text = val;
        this.snackbar.color = "info";
        this.snackbar.active = true;
      }
    },
    error(val) {
      if (val) {
        this.snackbar.text = val;
        this.snackbar.color = "error";
        this.snackbar.active = true;
      }
    }
  },
  computed: {
    info() {
      return this.$store.getters.info;
    },
    error() {
      return this.$store.getters.error;
    }
  }
};
</script>
